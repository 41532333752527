<template>
  <div class="bg__img">
    <div class="background--">
      <div v-if="!customVipuBackground" data-depth="0.4">
        <img src="../../assets/img/Background.svg" class="h-80 bg--" alt="background" />
      </div>
      <div v-else data-depth="0.4">
        <img src="../../assets/img/Background1.svg" class="h-80 bg--" alt="background" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Background",
  mounted() {
    new this.$parallax(document.querySelector(".background--"));
  },
  data: function() {
    return {
      customVipuBackground: this.isVipuBackground,
    };
  },
  props: ["isVipuBackground"],
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.bg__img {
  position: fixed;
  width: 100%;
}
.bg-- {
    animation: fadein 1s;
}
</style>
