<template>
  <div id="seyvom__page404">
    <nav class="nav__page404">
      <div class="logo__page4040">
        <a href="/">
          <svg viewBox="0 0 178.699 46">
            <g transform="translate(-162 -99)">
              <g transform="translate(23.625 15.548)">
                <g transform="translate(138.375 83.452)">
                  <path
                    style="fill: #c64342;"
                    d="M214.167,91.837V83.452H172.322v33.539h25.107v-8.385H180.691V91.837Z"
                    transform="translate(-168.167 -83.452)"
                  />
                  <path
                    style="fill: #c64342;"
                    d="M171.851,186.432H155.113v8.385h16.738v16.77H138.375v8.385H180.22V186.432Z"
                    transform="translate(-138.375 -173.971)"
                  />
                </g>
              </g>
              <g transform="translate(-83.889 -187)">
                <path
                  style="fill: #212121;"
                  d="M75.406,64.159a4.942,4.942,0,0,0-.734-.547,8.966,8.966,0,0,0-1.3-.677,11.233,11.233,0,0,0-1.656-.561,7.242,7.242,0,0,0-1.814-.231,4.067,4.067,0,0,0-2.447.6,2,2,0,0,0-.806,1.7,1.746,1.746,0,0,0,.3,1.051,2.61,2.61,0,0,0,.878.734,7.365,7.365,0,0,0,1.454.576q.878.259,2.03.547,1.5.4,2.721.864a7.384,7.384,0,0,1,2.073,1.152,4.761,4.761,0,0,1,1.31,1.656,5.5,5.5,0,0,1,.461,2.376,5.917,5.917,0,0,1-.619,2.807,5.207,5.207,0,0,1-1.67,1.886,7.258,7.258,0,0,1-2.419,1.051,12.159,12.159,0,0,1-2.865.331,15.387,15.387,0,0,1-4.55-.691,14.039,14.039,0,0,1-4.031-1.958L63.485,73.4a5.511,5.511,0,0,0,.936.677,14.184,14.184,0,0,0,1.6.835,12.393,12.393,0,0,0,2.045.705,9.223,9.223,0,0,0,2.3.288q3.282,0,3.282-2.1a1.725,1.725,0,0,0-.374-1.123,3.178,3.178,0,0,0-1.065-.806,10.208,10.208,0,0,0-1.67-.633q-.979-.287-2.188-.634A18.583,18.583,0,0,1,65.8,69.73,6.689,6.689,0,0,1,64,68.607a4.2,4.2,0,0,1-1.08-1.5,5.179,5.179,0,0,1-.36-2.03,6.351,6.351,0,0,1,.576-2.764,5.584,5.584,0,0,1,1.6-2.016,7.242,7.242,0,0,1,2.375-1.224,9.825,9.825,0,0,1,2.908-.418A11.292,11.292,0,0,1,74,59.336a14.816,14.816,0,0,1,3.167,1.6Z"
                  transform="translate(246.273 240.341)"
                />
                <path
                  style="fill: #212121;"
                  d="M208,76.612V80.1h-14.2V59.652h13.936v3.484h-9.963V68.06h8.61v3.225h-8.61v5.327Z"
                  transform="translate(133.343 239.492)"
                />
                <path
                  style="fill: #212121;"
                  d="M303.592,59.652l5.039,9.848,5.125-9.848h4.29l-7.429,13.3V80.1h-3.945V72.9l-7.4-13.245Z"
                  transform="translate(43.166 239.492)"
                />
                <path
                  style="fill: #212121;"
                  d="M434.134,59.652l5.529,15.463,5.471-15.463h4.175L441.333,80.1h-3.34l-8.034-20.444Z"
                  transform="translate(-68.574 239.492)"
                />
                <path
                  style="fill: #212121;"
                  d="M574.784,79.391a9.285,9.285,0,0,1-4.074-.878,10,10,0,0,1-3.139-2.332,10.816,10.816,0,0,1-2.75-7.155,10.272,10.272,0,0,1,.763-3.916,10.809,10.809,0,0,1,2.1-3.311,10.034,10.034,0,0,1,3.182-2.289,9.448,9.448,0,0,1,4-.849,8.978,8.978,0,0,1,4.046.907,10.109,10.109,0,0,1,3.124,2.376,10.942,10.942,0,0,1,2.016,3.34,10.519,10.519,0,0,1,.72,3.8,10.339,10.339,0,0,1-.749,3.9,10.6,10.6,0,0,1-2.073,3.282,9.973,9.973,0,0,1-3.167,2.275A9.444,9.444,0,0,1,574.784,79.391Zm-5.932-10.366a8.215,8.215,0,0,0,.4,2.563,6.838,6.838,0,0,0,1.166,2.188,5.793,5.793,0,0,0,1.872,1.526,5.8,5.8,0,0,0,5.068-.014,5.8,5.8,0,0,0,1.872-1.569,7,7,0,0,0,1.152-2.2,8.179,8.179,0,0,0,.389-2.491,7.976,7.976,0,0,0-.418-2.563,7.077,7.077,0,0,0-1.181-2.188,5.638,5.638,0,0,0-1.872-1.512,5.831,5.831,0,0,0-5.053.029,5.683,5.683,0,0,0-1.872,1.555,6.981,6.981,0,0,0-1.137,2.188A8.185,8.185,0,0,0,568.852,69.025Z"
                  transform="translate(-183.884 240.341)"
                />
                <path
                  style="fill: #212121;"
                  d="M740.187,80.1V66.563l-5.269,10.078h-2.16l-5.269-10.078V80.1h-3.974V59.652h4.261l6.047,11.633,6.1-11.633h4.233V80.1Z"
                  transform="translate(-319.572 239.492)"
                />
              </g>
            </g>
          </svg>
        </a>
      </div>
    </nav>
    <Background />
    <div
      data-aos="zoom-in"
      data-aos-duration="1000"
      class="row d-flex align-items-center justify-content-end"
    >
      <div class="col-md-5 page404_info__box">
        <div class="page404__info">
          <div class="page404__info_text_1">
            <div class="--page404__info_text_1__">
              <div data-depth="0.6">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="464"
                  height="288"
                  viewBox="0 0 464 288"
                >
                  <g transform="translate(-1227 -239)">
                    <line
                      style="fill: none;
                stroke: #212121;
                stroke-linecap: round;
                stroke-width: 5px;"
                      x2="179"
                      transform="translate(1229.5 345.5)"
                    />
                    <line
                      style="fill: none;
                stroke: #212121;
                stroke-linecap: round;
                stroke-width: 5px;"
                      x2="224"
                      transform="translate(1363.5 364.5)"
                    />
                    <text
                      style="fill: #212121; font-size: 72px;
                font-family: Montserrat-Bold, Montserrat;
                font-weight: 700;"
                      transform="translate(1230 309)"
                    >
                      <tspan x="0" y="0">Ooooops</tspan>
                    </text>
                    <text
                      style="fill: #212121; font-size: 56px;
                font-family: Montserrat-Light, Montserrat;
                font-weight: 300;"
                      transform="translate(1230 445)"
                    >
                      <tspan x="0" y="0">You managed to</tspan>
                      <tspan x="0" y="68">make us cry!</tspan>
                    </text>
                  </g>
                </svg>
              </div>
            </div>
          </div>
          <div class="page404__info_text_2">
            <div class="--page404__info_text_2__">
              <div data-depth="0.4">
                <p>
                  The page you requested could not be found.
                </p>
              </div>
            </div>
          </div>
          <div class="page404__info_btn">
            <a href="/" class="btn__homepage">HomePage</a>
          </div>
        </div>
      </div>
      <div class="col-md-6 page404_we__box">
        <div class="we__page404">
          <div class="--we__page404__">
            <div data-depth="0.8">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="651.614"
                height="944.692"
                viewBox="0 0 651.614 944.692"
              >
                <g transform="translate(-1356.011 -167.842)">
                  <path
                    style="fill: #c67a60;"
                    d="M2242.388,635.248c-18.994-15.213-32.336-34.4-38.524-57.941-1.812-6.888-6.219-8.552-11.373-10.587.616-2.629,9.742-1.932,2.749-7.782a9.144,9.144,0,0,1-2.641-9.976c4.725-11.009,2.037-22.578,2.361-33.868.277-9.606,1.723-18.412,7.76-26.283,6.49-8.463,8.9-18.711,10.691-28.952,1.929-11.027,8.257-16.193,19.164-16.4,3.1-.059,6.444.284,9.246-.82,14.467-5.68,26.727-3.467,36.417,9.227,12.774,2.66,24.6-2.157,36.524-5.082,10.1-2.466,16.045.672,18.911,10.374a126.013,126.013,0,0,0,17.672,36.114c7.992,11.557,5.033,25.121,4.562,37.856-.253,6.8,2.838,3.634,5.483,3.409,8.938,10.553-.7,21.666,1.014,32.435-7.088,2.382-7.7,9.141-9.637,14.793-6.444,18.92-15.536,36.367-32.006,48.122-8.472,6.047-4.8,13.749-6.349,20.788-6.75-6.164-13.681-6.651-22.877-4.37C2273.9,650.658,2256.608,648.553,2242.388,635.248Z"
                    transform="translate(-578.391 -188.061)"
                  />
                  <path
                    style="fill: #1c1c1c;"
                    d="M2231.281,268.692c-4.074.616-8.818-1.347-9.1-4.355-1.365-14.439-9.9-7.378-17.388-7.4-23.4-.086-30.44,12.448-39.794,33.159-9.813,21.719-11.57,41.5-11.052,63.356.111,4.506,1.119,10.112-6.672,8.768-7.705-10.2-6.4-22.911-9.53-34.392,3.859-3.526,7.606-6.885,5.421-13.148-4.222-12.112-1.828-24.748-2.4-37.125-.579-12.587,2.447-25.4-10.676-35.063-7.088-5.215-5.612-13.527,2.6-19.493,7.822-5.683,17.1-8.408,26.471-12.5-5.332-6.222-12.047-2.916-16.769-6.4-3.843-2.842-8.5-4.706-6.99-10.6,1.254-4.91,5.437-5.591,9.792-5.924,13.345-1.029,26.5,1.541,40.167,3.39-.616-2.355-.573-3.532-1.137-4.216-4.315-5.255-16.951-4.8-13.952-13.9,2.663-8.109,13.024-4.056,19.6-3.6,33.559,2.333,67.11,5.415,94.708,28.114,3.683,5.267,2.959,10.855.842,16.334C2275.319,239.848,2260.242,261.147,2231.281,268.692Z"
                    transform="translate(-532.371)"
                  />
                  <path
                    style="fill: #feeaec;"
                    d="M2173.447,1256.54c3.449,2.139,7.061,1.106,10.648.552,10.926,15.811,21.99,30.891,40.512,39.535,40.633,18.96,86.461,5.585,109.3-33.224a46.517,46.517,0,0,1,4.29-6.472c3.791.1,4.05,4.232,6.58,5.766,5.3,4.121,4.549,10.124,4.641,15.718.213,12.966-.588,25.978.234,38.891.962,15.065-6.022,23.654-19.709,27.365-45.484,12.328-89.993,27.932-135.415,40.42-7.5,2.065-16.109,5.825-23.1-2.207-4.228-9.986-2.61-20.514-2.7-30.82q-.308-37.424.019-74.848C2168.818,1270.076,2167.829,1262.5,2173.447,1256.54Z"
                    transform="translate(-562.084 -753.166)"
                  />
                  <path
                    style="fill: #c67a60;"
                    d="M2372.3,1178.482c1.975,3.794.323,7-1.643,10.149-17.684,28.314-43.545,42.6-76.528,41.985-32.234-.592-57.58-14.815-74.235-43.2-1.643-2.8-2.731-5.547-1.7-8.8,19.379-1.473,34.225-8.895,38.833-29.738,17.928,4.105,34.826-2.953,52.2-4.91,8.9-1,17.61-2.691,24.135,5.717C2339.537,1168.53,2352.845,1177.677,2372.3,1178.482Z"
                    transform="translate(-596.183 -674.707)"
                  />
                  <path
                    style="fill: #8d2b26;"
                    d="M2806.3,1334.647c42.8,25.4,57.016,64.817,55.324,112.316-.29,8.062-3.218,10.744-11.181,10.6-31.609-.555-63.223-.616-94.838-.838-4.9-6.09-1.849-11.613,1.1-17.259,16.079-30.82,32.807-61.331,41.3-95.461C2799.047,1339.862,2799.669,1334.311,2806.3,1334.647Z"
                    transform="translate(-966.454 -807.191)"
                  />
                  <path
                    style="fill: #8d2b26;"
                    d="M1938.735,1457.719c-31.109.222-62.228.219-93.331.817-8.6.167-12.109-2.694-12.294-11.428-1-46.79,13.308-85.783,55.223-111.083,6.133-.616,6.207,4.417,7.064,8.13,7.539,32.57,22.128,62.089,38.5,90.918C1937.786,1441.89,1944.317,1448.636,1938.735,1457.719Z"
                    transform="translate(-330.026 -808.118)"
                  />
                  <path
                    style="fill: #edd3d6;"
                    d="M2180.528,1384.412c39.391-6.42,76.091-22.3,114.368-32.638q21.645-5.856,43.111-12.349c9.292-2.817,14.229-8.155,13.561-19.241-1.134-19.136-.542-38.377-.678-57.574q1.624-3.107,3.255-6.21c5.856,5.714,5.239,13.206,5.289,20.359.185,32.031.04,64.062.1,96.092.019,10.414-3.557,20.19-4.848,30.36l-175.286-.08C2178.984,1396.845,2174.352,1390.3,2180.528,1384.412Z"
                    transform="translate(-568.2 -753.069)"
                  />
                  <path
                    style="fill: #ea5546;"
                    d="M2066.421,1375.869l-1.146,18.717a4.081,4.081,0,0,1-2.466-.475c.277-7.169-3.637-12.917-6.935-18.637-18.325-31.769-35.208-64.074-41.44-100.82-.142-.835-1.334-1.495-2.034-2.238,15.178-27.4,28.308-33.47,53.056-24.517Q2065.943,1311.888,2066.421,1375.869Z"
                    transform="translate(-454.093 -744.526)"
                  />
                  <path
                    style="fill: #ea5546;"
                    d="M2751.593,1395.151q-.265-73.406-.533-146.815c21.814-7.93,35.963-2.9,48.146,16.951,1.594,2.595,3.809,4.808,5.736,7.193-10.519,34.62-24.77,67.652-41.841,99.526-3.806,7.107-10.439,13.252-8.851,22.551A5.932,5.932,0,0,1,2751.593,1395.151Z"
                    transform="translate(-965.1 -745.008)"
                  />
                  <path
                    style="fill: #262626;"
                    d="M2468.61,334.966c24-13.721,43.764-31.18,51.348-59.149,1.07-3.945,1.319-8.115,1.951-12.177,17.589,21.423,40.34,39.927,34.909,72.972-2.336,14.229.253,29.254-1.883,43.545-1.233,8.216,2,10.9,7.492,13.909-4.651,5.548-4.845,13.456-9.905,18.785-4.358,4.235-.145,11.56-8.824,17.965,0-14.615-.388-26.97.133-39.286.367-8.657-1.149-15.456-7.545-22.363-8.469-9.138-12.494-21.266-15.209-33.593-1.578-7.169-4.509-10.207-13.031-7.162C2495.476,332.913,2482.4,336.759,2468.61,334.966Z"
                    transform="translate(-769.7 -66.274)"
                  />
                  <path
                    style="fill: #a57367;"
                    d="M2421.379,1102.038c-20.874-7.4-40.226,3.778-60.406,5.141-6.663.45-13.53,4.007-15.943-5.951l3.162-27c20.409,7.985,40.682,12.867,62.438,3.612,12.328-5.239,2.694,10.738,9.588,11.8Q2420.809,1095.841,2421.379,1102.038Z"
                    transform="translate(-684.207 -627.043)"
                  />
                  <path
                    style="fill: #a57367;"
                    d="M2732.112,705.6c2.367-6.756-5.754-19.065,9.905-18.785,16.643,14.84,12.328,40.213-8.713,51.114-7.656-1.165-4.814-6.472-3.359-10.149C2732.83,720.487,2732.811,713.133,2732.112,705.6Z"
                    transform="translate(-949.29 -359.028)"
                  />
                  <path
                    style="fill: #a57367;"
                    d="M2144.546,687c9.3-.018,5.991,7.4,6.78,12.038,1.258,7.4,1.849,14.9,2.749,22.353,1.834,5.581,17.243,11.835-.808,16.433C2133.362,725.494,2129.519,703.094,2144.546,687Z"
                    transform="translate(-539.174 -359.156)"
                  />
                  <path
                    style="fill: #50242b;"
                    d="M2338.374,607.5c-5.153,20.267-18,24.375-40.374,12.618C2314.581,620.087,2328.154,622.491,2338.374,607.5Z"
                    transform="translate(-651.672 -304.157)"
                  />
                  <path
                    style="fill: #8d3937;"
                    d="M2501.918,689.78c8.377,19.524,7.8,41.193-5.5,51.706C2510.723,722.285,2494.944,706.182,2501.918,689.78Z"
                    transform="translate(-788.939 -361.079)"
                  />
                  <path
                    style="fill: #4a242a;"
                    d="M2554.38,605.47c11.024,8.546,20.991,15.394,37.215,10.525C2574.653,629.216,2561.139,624.843,2554.38,605.47Z"
                    transform="translate(-829.036 -302.753)"
                  />
                  <path
                    style="fill: #262626;"
                    d="M2444.509,899.558c-19.127-8.546-47.86-9.554-63.729,1.4C2406.006,875.223,2431.759,886.944,2444.509,899.558Z"
                    transform="translate(-708.939 -496.567)"
                  />
                  <path
                    style="fill: #262626;"
                    d="M2455.72,960.592s6.324,12.636,12.109,15.179c6.5-4.7,9.4-15.36,9.4-15.36S2456.262,963.31,2455.72,960.592Z"
                    transform="translate(-760.783 -548.302)"
                  />
                  <path
                    style="fill: #262626;"
                    d="M2241.13,916.785s3.612,39.394,76.978,71.193c19.518-7.948,33.2-21.266,37.227-24.576,6.142-5.057,28.009-38.309,30.176-49.872-18.43,20.6-29.815,45.175-67.763,45.175-27.83,0-39.083-6.99-51.777-13.462C2254.231,939.255,2257.631,938.673,2241.13,916.785Z"
                    transform="translate(-612.329 -515.87)"
                  />
                  <path
                    style="fill: #d6e0f2;"
                    d="M2287.688,700.81,2273,791.672s11.961,22.135,25.374,24.224c5.156-26.024,12.26-114.27,12.26-114.27Z"
                    transform="translate(-634.377 -368.71)"
                  />
                  <path
                    style="fill: #63282a;"
                    d="M2343.47,697.267c-3.788,2.971-25.58.616-22.5-3.153C2322.14,691.251,2348.278,691.251,2343.47,697.267Z"
                    transform="translate(-667.358 -362.79)"
                  />
                  <path
                    style="fill: #d6e0f2;"
                    d="M2574.1,702.57l11.585,114.892s21.608-28.588,23.331-33.972-11.832-79.555-11.832-79.555Z"
                    transform="translate(-842.679 -369.927)"
                  />
                  <path
                    style="fill: #63282a;"
                    d="M2574.636,694.174c3.72-3.057,25.58-1.187,22.566,2.654C2596.1,699.719,2569.963,700.286,2574.636,694.174Z"
                    transform="translate(-842.663 -363.063)"
                  />
                  <path
                    style="fill: #8d3937;"
                    d="M2375.69,926.993s20.849-13.252,32.647-13.465,30.049,5.785,32.65,13.465C2413.672,915.21,2407.015,916.619,2375.69,926.993Z"
                    transform="translate(-705.418 -515.865)"
                  />
                  <path
                    style="fill: #c67a60;"
                    d="M1486.538,2300.261c-20.341,48.239-58.268,64-74.109,66.111-8.365,1.113-22.905-2.185-34.765-5.547a29.6,29.6,0,0,1-20.6-36.195c1.273-4.623,3.631-8.734,7.68-11.456,15.558-10.441,14.553-10.093,30.45-21.3s55.74-37.538,49.182-39.169"
                    transform="translate(0 -1442.32)"
                  />
                  <path
                    style="fill: #a57367;"
                    d="M1498.092,2265.678c-28.138-14.115-22.769-13.949-50.994,21.836,0,0-16.544,7.958-14.707-10.17s-3.939-44.664,19.968-52.063"
                    transform="translate(-52.741 -1423.344)"
                  />
                  <path
                    style="fill: #c67a60;"
                    d="M2998,2309c17.875,49.2,44.047,57.892,61.162,60.225s69.557-5.455,69.557-5.455,25.6-21.944,9.554-32.734-23.368-17.844-39.724-29.411-57.528-38.814-50.205-40.374"
                    transform="translate(-1135.934 -1448.228)"
                  />
                  <path
                    style="fill: #a57367;"
                    d="M3148.56,2275.646c32.009-13.977,26.129-13.869,53.743,22.788,0,0,17.357,8.281,16.979-10.131s8.321-45.28-17.13-53.083"
                    transform="translate(-1240.093 -1430.221)"
                  />
                  <rect
                    style="fill: #212121;"
                    width="575.537"
                    height="286.135"
                    transform="translate(1392.474 617.844)"
                  />
                  <path
                    style="fill: #fff;"
                    d="M1805.826,1978.485h-20.187v26.046h-49.706v-26.046H1657.14v-32.561l64.468-93.334h52.529l-57.306,86.172h20.621v-23.226h48.186v23.226h20.187Z"
                    transform="translate(-208.322 -1165.516)"
                  />
                  <path
                    style="fill: #fff;"
                    d="M2162.12,1920.764c0-50.359,28.662-79.444,68.808-79.444s68.808,29.084,68.808,79.444-28.662,79.444-68.808,79.444S2162.12,1971.12,2162.12,1920.764Zm86.172,0c0-29.3-7.378-38.42-17.364-38.42s-17.376,9.113-17.376,38.42,7.378,38.42,17.364,38.42,17.376-9.116,17.376-38.42Z"
                    transform="translate(-557.669 -1157.719)"
                  />
                  <path
                    style="fill: #fff;"
                    d="M2793.246,1978.485h-20.187v26.046H2723.35v-26.046h-78.79v-32.561l64.465-93.334h52.529l-57.3,86.172h20.618v-23.226h48.189v23.226h20.187Z"
                    transform="translate(-891.423 -1165.516)"
                  />
                  <path
                    style="fill: #474342;"
                    d="M2376.272,3143.916c.558,3.344,36.845,3.865,38.925,1.784s-5.646-37.141-5.646-37.141l-22.286,1.485S2374.183,3131.438,2376.272,3143.916Z"
                    transform="translate(-705.664 -2034.401)"
                  />
                  <path
                    style="fill: #474342;"
                    d="M2646.35,3154.959c4.136,2.068,24.366,3.862,26.742,0s-9.508-30.009-9.508-30.009l-18.421,1.486S2638.031,3150.8,2646.35,3154.959Z"
                    transform="translate(-889.872 -2045.74)"
                  />
                  <path
                    style="fill: #c67a60;"
                    d="M2471.511,2058.543l-4.2,1.563,4.315,14.985,36.17,5.621,2.019-20.9-10.667-3.026-23.522.925a13.307,13.307,0,0,0-4.115.829Z"
                    transform="translate(-768.801 -1306.782)"
                  />
                  <path
                    style="fill: #262626;"
                    d="M2459.422,1861.793c-5.153-9.585-8.445-13.823-10.127-21.91a10.87,10.87,0,0,1-1.7-4.91,93.528,93.528,0,0,1-.761-18.886c-.416-5.073-2.983-3.785-3.439-8.851-.69-7.7,11.9-16.116,16.643-22.233,27-11.095,33.07,8.155,41.317,19.358-.518,4.383-1.47,8.713-2.228,13.064-.219,6.441-1.461,12.762-2.222,19.142-.148,1.261-1,2.157-1.461,3.273-.872,3.618-1.634,4.275-2.7,7.837-1.6,5.356-4.888,7.819-9.653,15.425a3.079,3.079,0,0,1-1.652,1.541c-7.224,2.444-14.267,2.308-21.01-1.593A2.253,2.253,0,0,1,2459.422,1861.793Z"
                    transform="translate(-752.24 -1116.455)"
                  />
                  <path
                    style="fill: #c67a60;"
                    d="M2387.107,2497.05s-13.252-6.583-13.474,6.706,11.428,28.114,14.605,30.234,11.012.635,11.012.635,4.447-.848,2.117-4.873-5.147-15.718-7.477-19.94S2385.557,2496.748,2387.107,2497.05Z"
                    transform="translate(-703.993 -1610.228)"
                  />
                  <path
                    style="fill: #00a8db;"
                    d="M2421.441,2073.14c8.133,3.664,10.966,7.421,18.988,11.283,4.417,2.126,8.833,10.143,9.81,14.337.018,1.976,10.343,41.69,10.552,59.439.157,13.357-4.1,42.62-19.093-2.385,1.393,48.328-1.473,55.192-.795,60.1-20.631,7.335-40.294,5.822-81.133,1.791-6.839-.678-8.143.835-12.386-2.869-4.638-4.053-10.479-39.72-11.964-50.513-2.74-19.789,3.982-65.365,4.41-68.33,1.036-6.284,3.769-11.1,10.688-13.65,6.734-2.481,13.083-5.89,19.6-8.891,1.892.6,2.413,2.314,3.359,3.667,3.578,5.116,7.859,9.554,14.177,11.644,11.841,3.911,24.227,1.412,30.964-11.154C2419.432,2076.059,2419.654,2074.151,2421.441,2073.14Z"
                    transform="translate(-677.107 -1318.093)"
                  />
                  <path
                    style="fill: #00a8db;"
                    d="M2453.3,2067.117c.539-.37,1.091-.721,1.612-1.116,2.127-1.6,3.27-1.387,4.512,1.344,3.7,8.192,10.032,12.806,19.321,12.494,8.013-.268,13.468-4.441,16.791-11.567,1.824-3.92,1.769-3.954,5.548-1.954.484.256.925.583,1.387.878-2.5,8.79-7.375,15.718-16.486,18.184s-17.647.709-24.813-5.951a34.7,34.7,0,0,1-7.295-9.323C2453.394,2069.182,2452.439,2068.316,2453.3,2067.117Z"
                    transform="translate(-758.87 -1312.458)"
                  />
                  <path
                    style="fill: #a57367;"
                    d="M2495.424,2041.64c7.677,4.29,15.595,4.253,23.669,1.31.086,1.64.185,2.959.188,4.931,0,5.89-6.781,12.9-12.944,12.608-3.279-.157-11.314-5.51-10.91-11.526C2495.594,2046.374,2495.412,2045.141,2495.424,2041.64Z"
                    transform="translate(-788.242 -1296.301)"
                  />
                  <path
                    style="fill: #a57367;"
                    d="M2442.976,1893.34c1.248,6.133.755,12.436,1.825,18.587.308,1.72.425,3.473.632,5.208-6.894-3.264-7.434-8.5-7.48-15.931-.022-3.276-1.045-5.332,2.3-5.273C2442.381,1895.969,2442.939,1895.124,2442.976,1893.34Z"
                    transform="translate(-748.378 -1193.707)"
                  />
                  <path
                    style="fill: #a57367;"
                    d="M2612.33,1920.1c1.452-7.437,1.008-15.185,3.7-22.415.154.29.237.737.475.844,5.15,2.33,4.185,4.028,2.589,9.523C2617.634,1913.013,2616.728,1917.352,2612.33,1920.1Z"
                    transform="translate(-869.126 -1196.709)"
                  />
                  <path
                    style="fill: #065c70;"
                    d="M2450.887,2071.88c6.21,17.006,25.747,24.514,39.979,14,4.247-3.134,6.284-7.884,8.466-12.482.228-.487.484-.962.724-1.442l.724.308c-3.082,8.688-7.754,16.026-17.367,18.412-9.212,2.278-17.61.509-24.859-5.97a44.893,44.893,0,0,1-9.095-12.131A1.606,1.606,0,0,1,2450.887,2071.88Z"
                    transform="translate(-756.452 -1317.221)"
                  />
                  <path
                    style="fill: #c67a60;"
                    d="M2501.585,1890.565c-3,3.205.031-4.524-1.63-7.551a6.136,6.136,0,0,0-7.924-2.922,6.794,6.794,0,0,1-4.715.04c-9.049-2.977-5.51,9.314-9.039,11.1-2.758,1.384-4.155-7.878-5.307-9.517-1.11-1.766-2.09-9.2-2.82-10.7-3.39-7.212-1-17.425,1.455-24.656,7.138-.7,12.328.435,17.789-2.694,4.136-2.37,8.629-2.663,13.342-2.691,2.872-.019,3.791,4.762,4.9,7.3,3.31,7.582,5.282,15.305,1.52,23.37C2508.511,1873.34,2506.7,1885.094,2501.585,1890.565Z"
                    transform="translate(-769.603 -1157.477)"
                  />
                  <path
                    style="fill: #444;"
                    d="M2530.59,1985.686c-5.221-1.64-10.716-.589-15.12-.367C2518.755,1982.265,2526.642,1981.728,2530.59,1985.686Z"
                    transform="translate(-802.118 -1255.646)"
                  />
                  <path
                    style="fill: #444;"
                    d="M2536.594,1996.69c-2.718,2.111-4.623,2.426-6.9.589C2531.814,1996.126,2533.792,1996.69,2536.594,1996.69Z"
                    transform="translate(-811.956 -1265.109)"
                  />
                  <path
                    style="fill: #7a3633;"
                    d="M2531.761,1926.59c1.723,2.752.068,4.7.194,6.706s-.437,4.376,3.023,3.563c-.678,2.567-2.157,1.664-3.421,1.31-1.56-.438-1.556-1.461-1.233-2.857s.154-2.851.361-4.259C2530.886,1929.771,2531.29,1928.519,2531.761,1926.59Z"
                    transform="translate(-812.276 -1216.709)"
                  />
                  <path
                    style="fill: #1d4368;"
                    d="M2392.767,2532.27s-8.895,176.941-8.657,188.043,35.689.9,35.689.9l15.718-125.029,1.541-61.371C2414.3,2534.255,2392.767,2532.27,2392.767,2532.27Z"
                    transform="translate(-711.239 -1635.721)"
                  />
                  <path
                    style="fill: #234f82;"
                    d="M2576.694,2536.34c-10.149,1.442-31.941,1.378-45.635,1.042.5,6.639,2.271,32.817,6.112,61.618,0,0,23.6,120.687,28.154,124.912s23.423-5.162,23.423-5.162S2585.962,2599.853,2576.694,2536.34Z"
                    transform="translate(-812.903 -1638.537)"
                  />
                  <path
                    style="fill: #3f2030;"
                    d="M2573.382,1875.725a11.2,11.2,0,0,1,2.509,6.189c.025.817-.16,1.6-1.119,1.849-1.017.28-.616-1.131-1.023-2.1-.728-1.775-2.567-2.493-3.683-4.09a1.718,1.718,0,0,1,.444-2.654C2571.687,1874.172,2572.648,1874.659,2573.382,1875.725Z"
                    transform="translate(-839.587 -1180.715)"
                  />
                  <path
                    style="fill: #402031;"
                    d="M2498.1,1874.505c-.031.284.111,1-.182,1.319a33.588,33.588,0,0,1-5.394,4.253c-1.159.857-2.253,1.051-2.231-.675.028-1.868,3.942-6.078,5.742-6.219A1.955,1.955,0,0,1,2498.1,1874.505Z"
                    transform="translate(-784.698 -1179.757)"
                  />
                  <path
                    style="fill: #4c2833;"
                    d="M2505.628,1907.652c-.179,1.251-.123,3.011-1.541,3.039-1.7.037-1.68-1.785-1.735-3.107-.049-1.257.1-2.977,1.476-3C2505.487,1904.542,2505.348,1906.416,2505.628,1907.652Z"
                    transform="translate(-793.038 -1201.481)"
                  />
                  <path
                    style="fill: #4e2934;"
                    d="M2567.514,1904.838c-.407,1.276-.188,3.2-1.948,3.082-1.39-.1-1.412-1.812-1.381-3.057.031-1.347.086-3.119,1.775-3.03C2567.375,1901.9,2567.206,1903.71,2567.514,1904.838Z"
                    transform="translate(-835.815 -1199.58)"
                  />
                  <path
                    style="fill: #0c86a3;"
                    d="M2631.51,2212.28l24.058,70.253s5.532-5.884,6.222-9.181S2654.468,2267.783,2631.51,2212.28Z"
                    transform="translate(-882.395 -1414.351)"
                  />
                  <path
                    style="fill: #0c86a3;"
                    d="M2406.83,2212.28l1.655,98.844s2.157-26.53,2.056-29.9C2409.98,2261.033,2408.362,2272.329,2406.83,2212.28Z"
                    transform="translate(-726.961 -1414.351)"
                  />
                  <path
                    style="fill: #00a8db;"
                    d="M2705.537,2142.094s14.081,4.259,16.871-1.294c1.294,17.838,9.517,63.467,10.374,78.464-14.686,17.117-15.943,21.192-27.473,13.826C2706.718,2220.685,2700.224,2165.517,2705.537,2142.094Z"
                    transform="translate(-932.137 -1364.901)"
                  />
                  <path
                    style="fill: #0c86a3;"
                    d="M2702.35,2218.109s-8.18,32.052,13.117,22.729c1.292-17.718-6.823-61.217-13.53-91.889C2698.932,2151.061,2698.6,2181.548,2702.35,2218.109Z"
                    transform="translate(-929.507 -1370.539)"
                  />
                  <path
                    style="fill: #c67a60;"
                    d="M2701.24,2086.217s-6.583,13.252,6.706,13.474,13.138-5.772,15.259-8.938,1.11-11.024,1.11-11.024-.564-8.25-4.589-5.92-1.489,3.307-5.723,5.637S2700.938,2087.758,2701.24,2086.217Z"
                    transform="translate(-929.507 -1318.269)"
                  />
                  <path
                    style="fill: #212121;"
                    d="M2685.857,1635.508l-.518,15.046c-.16,4.7-3.011,8.737-7.125,9.841-4.315,1.162-9.3-1.159-10.636-16.615-1.27.524-3.868,1.448-3.868,1.448s1.8,22.092,12.066,20.991,13.157-7.178,13.817-9.582,8.879-216.631,8.879-216.631l-5.855-.737-6.759,196.238"
                    transform="translate(-904.671 -879.579)"
                  />
                  <path
                    style="fill: #768746;"
                    d="M2407.842,1531.785a28.321,28.321,0,0,1,.758-3.461c1.584-14.793,4.444-29.359,11.126-42.633.373-.925.382-1.822.755-2.734,1.479-2.361,2.774-4.906,4.256-6.9,10.417-12.886,20.831-24.859,32.475-35.917a135.72,135.72,0,0,0-134.762,134.592c19.54-7.991,37.994-14.312,53.848,1.1,3.911-2.718,17.093-9.825,30.857-9.862C2407.17,1554.555,2407.75,1543.142,2407.842,1531.785Z"
                    transform="translate(-668.586 -880.181)"
                  />
                  <path
                    style="fill: #596640;"
                    d="M2701.658,1531.352c-.348-2.009-.333-4.006-1.048-5.856-.887-2.931-1.593-6.041-3.03-8.79-8.093-14.858-12.713-30.231-20.625-45.086-.539-1.1-1.812-1.476-2.536-2.395a33.784,33.784,0,0,0-3.976-4.962,167.332,167.332,0,0,1-21.241-24.144h-1.886c-11.644,11.058-22.058,23.031-32.475,35.917-1.479,1.994-2.774,4.54-4.256,6.9-.373.925-.382,1.825-.755,2.734-6.682,13.274-9.554,27.855-11.126,42.633a30.12,30.12,0,0,0-.758,3.461c-.093,11.357-.672,22.77-.687,34.179,7.776-.028,15.737,2.2,22.316,8.747,13.77-10.83,32.2-18.106,50.448-2.709,8.287-9.425,25.106-12.041,38.524-8.836C2707.458,1552.479,2704.45,1542.142,2701.658,1531.352Z"
                    transform="translate(-858.701 -880.167)"
                  />
                  <path
                    style="fill: #424938;"
                    d="M2766.021,1440.14h-.191a167.345,167.345,0,0,0,21.241,24.144,33.776,33.776,0,0,1,3.976,4.962c.724.925,2,1.294,2.536,2.395,7.911,14.855,12.531,30.228,20.625,45.086a49.9,49.9,0,0,1,3.03,8.79c.715,1.831.7,3.84,1.048,5.856,2.774,10.787,5.791,21.127,6.9,31.8,7.027,1.677,13.126,4.953,16.581,9.683,12.276-19.053,37.424-7.668,51.6-.746C2893.987,1497.15,2840.977,1440.76,2766.021,1440.14Z"
                    transform="translate(-975.318 -880.181)"
                  />
                  <path
                    style="fill: #637241;"
                    d="M2497.17,1575.836s16.729-120.113,82.991-135.7c0,0-49.509,82.214-29.818,134.571C2532.763,1560.151,2510.678,1568.4,2497.17,1575.836Z"
                    transform="translate(-789.458 -880.181)"
                  />
                  <path
                    style="fill: #4c563c;"
                    d="M2766.45,1440.14s34.016,63.3,20.631,131.862c16.316-8.066,31.405-17.148,55.112.847C2838.756,1542.809,2831.535,1465.773,2766.45,1440.14Z"
                    transform="translate(-975.747 -880.181)"
                  />
                  <path
                    style="fill: #212121;"
                    d="M2759.569,1387.6l-2.114,15.924a1.306,1.306,0,0,0,1.319,1.476l3.652-.071a.881.881,0,0,0,.863-.835l.854-16.359Z"
                    transform="translate(-969.517 -843.834)"
                  />
                  <path
                    style="fill: #a57367;"
                    d="M2721.7,2079.973s3.551-2.774-.847-4.093-8.571-5.058-12.747-.659-5.276,12.747-5.276,14.066-.437,11.431,2.857,9.012,6.376-13.406,6.164-16.7C2721.2,2080.759,2710.489,2082.611,2721.7,2079.973Z"
                    transform="translate(-931.719 -1318.093)"
                  />
                  <path
                    style="fill: #919fb5;"
                    d="M2639.923,1257.44s-19.191,47.582-1.35,47.582C2664.887,1305.022,2651.327,1274.677,2639.923,1257.44Z"
                    transform="translate(-882.028 -753.789)"
                  />
                  <path
                    style="fill: #efefef;"
                    d="M2671.489,1316.63s2.977,1.988,4.888,13.918c1.982,12.362-10.907,14.464-10.907,14.464S2678.125,1335.938,2671.489,1316.63Z"
                    transform="translate(-905.889 -794.736)"
                  />
                  <path
                    style="fill: #919fb5;"
                    d="M2278.969,1657.13s-12.457,30.891-.875,30.891C2295.174,1688.02,2286.365,1668.321,2278.969,1657.13Z"
                    transform="translate(-634.49 -1030.296)"
                  />
                  <path
                    style="fill: #efefef;"
                    d="M2299.421,1695.55s1.936,1.294,3.178,9.036c1.285,8.028-7.089,9.391-7.089,9.391S2303.736,1708.087,2299.421,1695.55Z"
                    transform="translate(-649.949 -1056.875)"
                  />
                  <path
                    style="fill: #919fb5;"
                    d="M2298.484,1158.73s-14.029,34.786-.986,34.786C2316.736,1193.516,2306.815,1171.332,2298.484,1158.73Z"
                    transform="translate(-647.484 -685.501)"
                  />
                  <path
                    style="fill: #efefef;"
                    d="M2321.541,1202s2.176,1.455,3.575,10.17c1.448,9.039-7.976,10.574-7.976,10.574S2326.4,1216.118,2321.541,1202Z"
                    transform="translate(-664.913 -715.435)"
                  />
                  <path
                    style="fill: #919fb5;"
                    d="M2755.9,1513.042s2.271,2.08,5.994,5.215c3.165,2.666,4.651,6.839,6.164,4.17C2772.965,1513.865,2763.636,1512.54,2755.9,1513.042Z"
                    transform="translate(-968.449 -930.551)"
                  />
                  <path
                    style="fill: #efefef;"
                    d="M2769.86,1516.194s1.2-.6,5.44,1c4.392,1.655,2.678,6.241,2.678,6.241S2777.38,1517.624,2769.86,1516.194Z"
                    transform="translate(-978.106 -932.72)"
                  />
                  <path
                    style="fill: #919fb5;"
                    d="M2575.21,1490.537s-2.1,1.233-5.51,3.082c-2.9,1.563-4.759,4.37-5.474,2.311C2561.939,1489.316,2569.348,1489.362,2575.21,1490.537Z"
                    transform="translate(-835.554 -914.629)"
                  />
                  <path
                    style="fill: #efefef;"
                    d="M2572.643,1492.21s-.82-.552-4.355.136a3.658,3.658,0,0,0-3.137,4.154S2566.626,1492.432,2572.643,1492.21Z"
                    transform="translate(-836.469 -916.058)"
                  />
                  <path
                    style="fill: #919fb5;"
                    d="M2669.027,1515.342c-2.774,1.849-9.628,2.33-4.065,4.225s2.869,4.336,4.531,6.056c.632.653,1.3-.925,3.011-3.683.586-1.849,6.216-1.091,7.089,1.048s5.347,4.734,4.49,1.942-4.062-6.888-1.282-6.78,7.659-.505,5.988-1.051C2677.1,1513.278,2671.807,1513.487,2669.027,1515.342Z"
                    transform="translate(-904.073 -931.314)"
                  />
                  <path
                    style="fill: #fff;"
                    d="M2677.5,1533.7a.339.339,0,0,0-.185.616,2.3,2.3,0,0,1,1,1.476c.243,1.378.308,3.954,1.714,4.078.653.058,1.963-5.107,3.473-5.212s-.345-.724-.986.052-2.188,5.014-2.7,4.358C2677.907,1535.95,2679.729,1533.691,2677.5,1533.7Z"
                    transform="translate(-913.984 -944.906)"
                  />
                  <path
                    style="fill: #fff;"
                    d="M2717.852,1530s3.344,5.945,2.352,7.036-4.112-1.738-5.064-3.812c2.256,2.157,3.594,3.513,4.047,3.141C2719.8,1535.856,2718.037,1531.3,2717.852,1530Z"
                    transform="translate(-940.251 -942.347)"
                  />
                  <path
                    style="fill: #7f94b2;"
                    d="M2651.858,1500.708c2.466,2.059,10.907.509,15.829-1.522s3.048-5.723,8.075-6.524,1.122-5.082-3.424-2.139-6.361,4.971-7.915,1.818-4.062-4.623-6.1-2.839.376,7.132-2.62,5.708-13.4-9.332-12.02-3.245c.456,2.031,3.769,2.016,5.082,3.245C2650.521,1496.862,2650.447,1499.527,2651.858,1500.708Z"
                    transform="translate(-890.733 -913.797)"
                  />
                  <path
                    style="fill: #fff;"
                    d="M2652.559,1496.552s-4.376-1.057-5.8-2.7S2652.309,1495.43,2652.559,1496.552Z"
                    transform="translate(-892.814 -917.023)"
                  />
                  <path
                    style="fill: #fff;"
                    d="M2728.88,1495.286s4.931-3.51,6.876-2.567C2737.765,1493.683,2733.663,1493.468,2728.88,1495.286Z"
                    transform="translate(-949.756 -916.444)"
                  />
                  <path
                    style="fill: #fff;"
                    d="M2690.415,1496.111c.392-1.069,3.923-7.591.675-5.008C2689.392,1492.452,2689.657,1495.716,2690.415,1496.111Z"
                    transform="translate(-922.738 -915.022)"
                  />
                </g>
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Background from "@/components/shared/background.vue";
export default {
  name: "Home",
  components: {
    Background,
  },
  mounted() {
    this.$destroy();
    new this.$parallax(document.querySelector(".--we__page404__"));
    new this.$parallax(document.querySelector(".--page404__info_text_1__"));
    new this.$parallax(document.querySelector(".--page404__info_text_2__"));
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
#seyvom__page404 {
  padding-top: 2em;
  padding-left: 4em;
  padding-right: 4em;
  height: 100%;
  width: 100%;
  position: fixed;
}
.nav__page404 {
  padding: 4em;
  position: absolute;
}
.logo__page4040 {
  position: absolute;
  z-index: 99;
  width: 178px;
  height: 46px;
}
.we__page404 {
  padding-top: 2em;
  transform: scale(0.9) translate(0px, -50px);
}
.btn__homepage {
  color: #212121 !important;
  border-radius: 7px;
  padding: 15px;
  width: 150px;
  text-align: center;
  font-size: 18px;
  font-family: Poppins-Bold, Poppins;
  font-weight: 700;
  box-shadow: 5px 10px;
  text-decoration: none;
  background-color: #ffce00;
  -webkit-box-shadow: 3px 6px 13px -3px rgba(0, 0, 0, 0.36);
  -moz-box-shadow: 3px 6px 13px -3px rgba(0, 0, 0, 0.36);
  box-shadow: 3px 6px 13px -3px rgba(0, 0, 0, 0.36);
  animation: fadein 1.5s;
}
.btn__homepage:hover {
  background-color: #e6bf00;
}
.page404__info {
  padding-top: 16em;
}
.page404__info_text_2 {
  color: #212121;
  font-size: 28px;
  font-family: Montserrat-Regular, Montserrat;
}
.page404__info_btn {
  padding-top: 1em;
}
.page404__info_text_1 {
  position: absolute;
  transform: translate(-5px, -320px);
}
@media only screen and (max-width: 1515px) {
  .nav__page404 {
    padding: 3em;
  }
  .we__page404 {
    transform: scale(0.8) translate(-80px, -130px);
  }
  .page404__info {
    padding-top: 4em;
  }
  .page404__info_text_1 {
    transform: scale(0.8) translate(-65px, -320px);
  }
  .page404__info_text_2 {
    padding-top: 1em;
    font-size: 20px;
  }
  .btn__homepage {
    font-size: 16px;
    padding: 14px;
  }
}
@media only screen and (max-width: 1050px) {
  #seyvom__page404 {
    padding-left: 2em;
    padding-right: 2em;
  }
  .nav__page404 {
    padding: 2em;
  }
  .page404__info {
    padding-top: 0em;
    transform: translate(0px, -100px);
  }
  .we__page404 {
    transform: scale(0.6) translate(-160px, -320px);
  }
  .page404__info_text_1 {
    transform: scale(0.6) translate(-160px, -380px);
  }
  .page404__info_text_2 {
    font-size: 16px;
  }
  .btn__homepage {
    font-size: 14px;
    padding: 12px;
  }
}
@media only screen and (max-height: 820px) and (min-width: 1050px) {
  .we__page404 {
        transform: scale(0.7) translate(-80px, -230px);
  }
}
/*responsive mobile*/
@media only screen and (max-width: 767px) {
  .nav__page404 {
    padding: 1em;
  }
  .page404_info__box {
    order: 1;
    padding-top: 5em;
    display: flex;
    justify-content: center;
    text-align: center;
  }
  .we__page404 {
    transform: scale(0.4) translate(110px, 0px);
    height: 300px;
    padding-top: unset;
  }
  .page404__info {
    transform: translate(0px, 190px);
  }
  .page404__info_btn {
    float: right;
  }
}
@media only screen and (max-width: 630px) {
  .we__page404 {
    transform: scale(0.4) translate(30px, 0px);
  }
}
@media only screen and (max-width: 500px) {
  .page404_info__box {
    padding-top: 1em;
  }
  .we__page404 {
    transform: scale(0.3) translate(90px, -100px);
    height: 300px;
  }
  .page404__info {
    transform: translate(0px, 100px);
  }
  .page404__info_text_1 {
    transform: scale(0.5) translate(-160px, -380px);
  }
  .page404__info_text_2 {
    text-align: left;
    padding-top: 3em;
    font-size: 16px;
    padding-left: 2.3em;
  }
  .logo__page4040 {
    width: 150px;
  }
}
@media only screen and (max-width: 400px) {
  #seyvom__page404 {
    padding-left: 0.5em;
    padding-right: 0.5em;
  }
  .page404__info_btn {
    padding-right: 1em;
  }
}
@media only screen and (max-height: 640px) and (max-width: 380px) {
  .we__page404 {
    transform: scale(0.25) translate(60px, -180px);
    height: 300px;
  }
  .page404__info_text_1 {
    transform: scale(0.4) translate(-200px, -610px);
  }
  .page404__info_text_2 {
    transform: translate(0px, -30px);
    padding-top: 0em;
    font-size: 14px;
    padding-left: 4em;
    padding-right: 2em;
  }
  .page404__info_btn {
    padding-right: 1.5em;
    transform: translate(0px, -50px);
  }
  .btn__homepage {
    font-size: 12px;
    padding: 10px;
  }
}
</style>
